<template>
  <el-container class="home-container">
    <el-header class="root-header">
      <div class="header-bar">
        <div class="header-bar-left">
          <el-image :src="logo" />
          科普后台管理系统
        </div>
        <div class="header-bar-right">
          <el-dropdown class="header-status__dropDown">
            <div class="userInfo">
              <i class="iconjr-icon-news" />
              <span class="userInfo_border"></span>
              <el-avatar
                shape="circle"
                :size="18"
                icon="el-icon-user-solid"
                style="vertical-align: sub"
              />
              <!--              {{ profile.username }}-->
              {{ userName }}
              <i class="el-icon-caret-bottom" />
            </div>
            <el-dropdown-menu>
              <el-dropdown-item @click.native="_logout">退出</el-dropdown-item>
              <el-dropdown-item @click.native="dialogVisible = true"
                >修改密码</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dialog
            ref="addVisible"
            title="修改密码"
            :visible.sync="dialogVisible"
            width="25%"
            @close="handleClose"
          >
            <el-form
              ref="updatePwdObj"
              :model="updatePwdObj"
              :rules="updatePwdRules"
              label-width="80px"
            >
              <el-form-item label="旧密码" prop="oldPassword">
                <el-input
                  v-model="updatePwdObj.oldPassword"
                  placeholder="请输入旧密码"
                />
              </el-form-item>
              <el-form-item label="新密码" prop="password">
                <el-input
                  v-model="updatePwdObj.password"
                  placeholder="请输入新密码"
                />
              </el-form-item>
              <el-form-item label="确认密码" prop="surePassword">
                <el-input
                  v-model="updatePwdObj.surePassword"
                  placeholder="请确认密码"
                />
              </el-form-item>
            </el-form>
            <div>
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="warning" @click="submitForm">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </el-header>

    <el-container class="home-main">
      <ly-aside :is-collapse.sync="isCollapse" />
      <!-- 右侧主体 -->
      <el-container>
        <el-header class="main_header">
          <Breadcrumb :is-collapse.sync="isCollapse" />
        </el-header>

        <el-main class="ly-main">
          <router-view class="main-view" />
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import LyAside from "./components/Aside/index";
import Breadcrumb from "./components/Breadcrumb/index";
import logo from "../../static/images/logo.png";
import { updatepwd } from "@/api/login";

export default {
  name: "Layout",

  components: {
    LyAside,
    Breadcrumb,
  },

  data() {
    return {
      isCollapse: false,
      logo,
      dialogVisible: false,
      updatePwdObj: {
        oldPassword: null,
        password: null,
        userId: null,
        surePassword: null,
      },
      userName: null, //当前登录账号
      updatePwdRules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        surePassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.userName = sessionStorage.getItem("userName");
  },
  methods: {
    _logout() {
      this.$message.success({
        message: "登出成功",
        duration: 800,
        onClose: async () => {
          sessionStorage.removeItem("token");
          location.reload();
        },
      });
    },

    async updatePassword() {
      this.dialogVisible = true;
    },

    handleClose() {
      this.$refs.updatePwdObj.resetFields();

      this.dialogVisible = false;
    },

    submitForm() {
      this.$refs.updatePwdObj.validate(async (valid) => {
        if (valid) {
          const userId = sessionStorage.getItem("userId");

          Object.assign(this.updatePwdObj, { userId });

          const { data: res } = await updatepwd(this.updatePwdObj);

          if (res.success) {
            this.$message.success({
              message: "密码修改成功",
              duration: 800,
              onClose: async () => {
                sessionStorage.removeItem("token");
                location.reload();
              },
            });

            this.dialogVisible = false;
          } else {
            this.$messageError(res.message);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.home-container {
  /deep/ .el-header {
    height: auto !important;
  }
  height: 100%;
  .root-header {
    height: 64px !important;
    line-height: 64px;
    border-bottom: 2px solid #f5f5f5;
    // background: #71ba7c;
    padding: 0 30px;
    .header-bar {
      height: 64px;
      display: flex;
      justify-content: space-between;
      .header-bar-left {
        font-size: 18px;
        font-family: "zcoolwenyiti";
        color: #151515;
        .el-image {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .header-bar-right {
        .header-status__dropDown {
          .userInfo {
            color: #151515;
            &_border {
              border-right: 1px solid #151515;
              margin: 0 25px;
            }
            i {
              color: #151515;
            }
            .el-avatar {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .ly-main {
    height: 0;
    margin-top: 5px;
    overflow-x: hidden; // TODO 此处可能会影响到整个main的滚动

    .main-view {
      height: 100%;
    }
  }
  .home-main {
    background: #f5f7f9;
    .main_header {
      margin-top: 16px;
    }
  }
}
</style>

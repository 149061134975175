export default {
  state: {
    Authorization: null,
    userInfo: null,
    menuRouteLoaded: false, // 菜单和路由是否已经加载
    menuTree: null, // 导航菜单树
    pathName: null,
    tagDate: (new Date()).toString()
  },
  getters: {
    getUserInfo(state) {
      if (!state.userInfo) {
        state.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      }
      return state.userInfo
    },
    getAuthorization(state) {
      if (!state.Authorization) {
        state.Authorization = JSON.parse(sessionStorage.getItem("Authorization"))
      }
      return state.Authorization
    },
    getMenuTree(state) {
      if (!state.menuTree) {
        state.menuTree = JSON.parse(sessionStorage.getItem("menuTree"))
      }
      return state.menuTree
    },
    isMenuRouteLoaded(state) {
      if (!state.menuRouteLoaded) {
        state.menuRouteLoaded = sessionStorage.getItem("menuRouteLoaded")
      }
      return state.menuRouteLoaded
    },
    getPathName(state) {
      return state.pathName
    },
    getTagDate(state) {
      return state.tagDate
    }
  },
  mutations: {
    login(state, data) {
      state.userInfo = data.userInfo
      if (state.userInfo) {
        sessionStorage.setItem("userInfo", JSON.stringify(data.userInfo))
      }
      state.Authorization = data.Authorization
      if (state.Authorization) {
        sessionStorage.setItem("Authorization", JSON.stringify(data.Authorization))
      }
      state.menuTree = data.menuTree
      if (state.menuTree) {
        sessionStorage.setItem("menuTree", JSON.stringify(data.menuTree))
      }
    },
    logout(state) {
      state.userInfo = null
      state.Authorization = null
      state.menuTree = null
      sessionStorage.removeItem("userInfo")
      sessionStorage.removeItem("Authorization")
      sessionStorage.removeItem("menuTree")
      sessionStorage.removeItem("tags")
      state.menuRouteLoaded = false
    },
    menuRouteLoaded(state) {
      state.menuRouteLoaded = true
    },
    setPathName(state, data) {
      state.pathName = data
    },
    setTagDate(state) {
      state.tagDate = (new Date()).toString()
    }
  },
  actions: {

  }
}

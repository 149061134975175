import RouterView from '@/components/RouterView'

const menuList = [{
    path: '/activity',
    name: 'Activity',
    component: RouterView,
    redirect: '/activity/list',
    meta: {
        title: '活动管理',
        isMenu: true
    },
    children: [{
        path: '/activity/list',
        name: 'ActivityList',
        component: () =>
            import ('../views/activity/index.vue'),
    }, {
        path: '/activity/addActivityInfo',
        name: 'addActivity',
        component: () =>
            import ('../views/activity/components/activityInfo.vue'),
        meta: {
            title: '新建活动',
            isMenu: true
        }
    }, {
        path: '/activity/editActivityInfo',
        name: 'addActivity',
        component: () =>
            import ('../views/activity/components/activityInfo.vue'),
        meta: {
            title: '编辑活动',
            isMenu: true
        }
    }, {
        path: '/activity/worksList',
        name: 'worksList',
        component: () =>
            import ('../views/activity/worksManagement/index.vue'),
        meta: {
            title: '作品管理',
            isMenu: true
        },
    }, {
        path: '/activity/worksList/comment',
        name: 'comment',
        component: () =>
            import ('../views/activity/worksManagement/comment.vue'),
        meta: {
            title: '点评',
            isMenu: true
        },
    }, {
        path: '/activity/addInformationTable',
        name: 'addInformationTable',
        component: () =>
            import ('../views/activity/informationTable/index.vue'),
        meta: {
            title: '新建信息表',
            isMenu: true
        }
    }, {
        path: '/activity/editInformationTable',
        name: 'addInformationTable',
        component: () =>
            import ('../views/activity/informationTable/index.vue'),
        meta: {
            title: '编辑信息表',
            isMenu: true
        }
    }, {
        path: '/activity/informationTable',
        name: 'informationTable',
        component: () =>
            import ('../views/activity/informationTable/informationList.vue'),
        meta: {
            title: '信息收集表',
            isMenu: true
        }
    }]
}, {
    path: '/special',
    name: 'Special',
    component: RouterView,
    redirect: '/special/list',
    meta: {
        title: '专题管理',
        isMenu: true
    },
    children: [{
        path: '/special/list',
        name: 'SpecialList',
        component: () =>
            import ('../views/special/index.vue')
    }, {
        path: '/special/addSpecilInfo',
        name: 'specilInfo',
        component: () =>
            import ('../views/special/components/specialInfo.vue'),
        meta: {
            title: '新建专题',
            isMenu: true
        }
    }, {
        path: '/special/editSpecilInfo',
        name: 'specilInfo',
        component: () =>
            import ('../views/special/components/specialInfo.vue'),
        meta: {
            title: '编辑专题',
            isMenu: true
        }
    }]
}, {
    path: '/book',
    name: 'Book',
    component: RouterView,
    redirect: '/book/list',
    meta: {
        title: '书籍管理',
        isMenu: true
    },
    children: [{
        path: '/book/list',
        name: 'Book',
        component: () =>
            import ('../views/book/index.vue')
    }, {
        path: '/book/info/add',
        name: 'bookInfo',
        component: () =>
            import ('../views/book/bookInfo.vue'),
        meta: {
            title: '新建书籍',
            isMenu: true
        }
    }, {
        path: '/book/info/edit',
        name: 'bookInfo',
        component: () =>
            import ('../views/book/bookInfo.vue'),
        meta: {
            title: '编辑书籍',
            isMenu: true
        }
    }]
}, {
    path: '/message',
    name: 'Message',
    component: RouterView,
    redirect: '/message/leave',
    meta: {
        title: '留言管理',
        isMenu: true
    },
    children: [{
            path: '/message/leave',
            name: 'Leave',
            component: () =>
                import ('../views/message/leave/index.vue')
        },
        {
            path: '/message/answer',
            name: 'Answer',
            component: () =>
                import ('../views/message/answer/index.vue')
        }, {
            path: '/message/leaveInfo',
            name: 'leaveInfo',
            component: () =>
                import ('../views/message/leave/leaveInfo.vue'),
            meta: {
                title: '聊天',
                isMenu: true
            },
        }
    ]
}, {
    path: '/user',
    name: 'User',
    component: RouterView,
    redirect: '/user/list',
    meta: {
        title: '用户管理',
        isMenu: true
    },
    children: [{
        path: '/user/list',
        name: 'User',
        component: () =>
            import ('../views/user/index.vue')
    }]
}, {
    path: '/systemManagement',
    name: 'SystemManagement',
    component: RouterView,
    redirect: '/systemManagement/menu',
    meta: {
        title: '系统管理',
        isMenu: true
    },
    children: [{
            path: '/systemManagement/menu',
            name: 'Menu',
            component: () =>
                import ('../views/SystemManagement/Menu'),
            meta: {
                title: '菜单管理',
                isMenu: true
            }
        },
        {
            path: '/administrator',
            name: 'Administrator',
            component: RouterView,
            redirect: '/systemManagement/administrator',
            children: [{
                    path: '/systemManagement/administrator',
                    name: 'Administrator',
                    component: RouterView,
                    component: () =>
                        import ('../views/SystemManagement/Administrator'),
                },
                {
                    path: '/systemManagement/administrator/userInformation',
                    name: 'Information',
                    component: () =>
                        import ('../views/SystemManagement/Administrator/information.vue'),
                }
            ],
            meta: {
                title: '管理员管理',
                isMenu: true
            }
        },
        {
            path: '/systemManagement/role',
            name: 'Role',
            component: () =>
                import ('../views/SystemManagement/Role'),
            meta: {
                title: '角色管理',
                isMenu: true
            }
        }
    ]
}, {
    path: '/operation',
    name: 'Operation',
    meta: {
        title: '运营管理',
        isMenu: true
    },
    component: RouterView,
    redirect: '/operation/invitation',
    children: [{
        path: '/operation/invitation',
        component: RouterView,
        redirect: '/operation/invitation/list',
        children: [{
            meta: {
                title: '邀请码管理',
                isMenu: true
            },
            path: '/operation/invitation/list',
            name: 'invitationCode',
            component: () =>
                import ('../views/operation/invitationCode/index.vue')
        }, {
            meta: {
                title: '新建邀请码',
                isMenu: true
            },
            path: '/operation/invitation/add',
            name: 'invitationAdd',
            component: () =>
                import ('../views/operation/invitationCode/invitationAdd.vue')
        }, {
            meta: {
                title: '编辑邀请码',
                isMenu: true
            },
            path: '/operation/invitation/edit',
            name: 'invitationEdit',
            component: () =>
                import ('../views/operation/invitationCode/invitationEdit.vue')
        }, {
            meta: {
                title: '核销信息',
                isMenu: true
            },
            path: '/operation/information',
            name: 'information',
            component: () =>
                import ('../views/operation/invitationCode/information.vue')
        }]
    }, {
        path: '/operation/channel',
        name: 'Channel',
        meta: {
            title: '渠道管理',
            isMenu: true
        },
        component: () =>
            import ('../views/operation/channel/index.vue')
    }]
}]


export default menuList
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import less from 'less'
import 'element-ui/lib/theme-chalk/index.css'
import '../static/fonts/iconfont.css'
import '../static/css/bts.css'
import '../static/css/index.css'
import '../static/css/global.css'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
// ue富文本框
import '../public/UEditor/ueditor.config.js'
import '../public/UEditor/ueditor.all.min.js'
import '../public/UEditor/lang/zh-cn/zh-cn.js'
import '../public/UEditor/ueditor.parse.min.js'

//复制
import VueClipboard from 'vue-clipboard2'

import {
  message,
  MessageError,
  MessageSuccess,
  MessageWaring
} from "./utils/tool"

Vue.use(ElementUI);
Vue.use(less)
Vue.use( VueClipboard )
Vue.config.productionTip = false
Vue.prototype.$message = message

Vue.prototype.$messageError = MessageError

Vue.prototype.$messageSuccess = MessageSuccess

Vue.prototype.$messageWarning = MessageWaring

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
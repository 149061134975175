import Vue from 'vue'
import Router from 'vue-router'
import menuList from './menuList'
import Layout from '@/layout/index.vue'
Vue.use(Router)

// 最基础的页面结构，没有权限也可以看到
export const routes = [{
    path: '/login',
    name: 'Login',
    component: () => import('@/layout/components/Login/index.vue'),
    hidden: true
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/activity',
    component: Layout,
    meta: { title: '当前位置:' },
    children: menuList
  },
  {
    path: '/:id',
    component: () => import('@/layout/components/404')
  }
]


// fix Error: Avoided redundant navigation to current location.
const resolveOriginal = Router.prototype.push

Router.prototype.push = function push (location) {
  return resolveOriginal.call(this, location).catch(err => err)
}

const router = new Router({
  routes,
  mode: 'history',
  linkActiveClass: 'active-class',
  linkExactActiveClass: 'exact-class'
})

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token')
  if (to.path !== '/login' && token !== null) {
    next()
  } else if (to.path !== '/login' && token === null) {
    next({ path: '/login' })
  } else if (to.path === '/login' && token === null) {
    next()
  } else {
    next({ path: '/' })
  }
  next()
})

export default router

<template>
  <div class="menuList hp-100">
    <el-aside :width="isCollapse ? '64px' : '200px'">
      <el-scrollbar
        wrap-class="scrollbar-wrapper"
        :native="false"
        wrap-style="color: red; height:770px;overflow-x: hidden;"
      >
        <el-menu
          background-color="#fff"
          text-color="#222222"
          active-text-color="#8483d0"
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          unique-opened
          :default-active="activePath"
        >
          <template v-for="item in menuList">
            <!-- 一级菜单 -->
            <router-link
              :to="item.url"
              :key="item.id"
              v-if="item.children.length === 0"
            >
              <el-menu-item :index="item.url + ''">
                <i :class="item.icon" />
                <span>{{ item.name }}</span>
              </el-menu-item>
            </router-link>
            <el-submenu
              v-else
              :key="item.id"
              :index="item.url + ''"
              @click.stop="handleClick(item)"
            >
              <!-- 一级菜单模板区域 -->
              <template slot="title">
                <!-- 图标 -->
                <i :class="item.icon" />
                <!-- 文本 -->
                <span>{{ item.name }}</span>
              </template>
              <!-- 二级菜单 -->
              <el-menu-item
                v-for="subItem in item.children"
                :key="subItem.id"
                :index="subItem.url"
                @click="saveNavState(subItem.url)"
              >
                <template slot="title">
                  <!-- 图标 -->
                  <!--  <i :class="subItem.meta.icon" />-->
                  <!-- 文本 -->
                  <span>{{ subItem.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </el-scrollbar>
    </el-aside>
  </div>
</template>
<script>
import { obtainMenuList } from "@/api/menuList";
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuList: [
        {
          children: [],
          createTime: "2021-04-13 13:13:22",
          icon: "el-icon-money",
          id: 1,
          menuType: "DIRECTORY",
          name: "活动管理",
          orderNum: 1,
          parentId: 0,
          perms: "Activity",
          updateTime: "2021-04-23 13:39:05",
          url: "/activity",
        },
        {
          children: [
            {
              createTime: "2021-04-13 13:13:22",
              id: 11,
              menuType: "MENU",
              name: "专题管理",
              orderNum: 1,
              parentId: 0,
              perms: "Special",
              updateTime: "2021-04-23 13:39:05",
              url: "/special",
            },
            {
              createTime: "2021-04-13 13:13:22",
              id: 12,
              menuType: "MENU",
              name: "书籍管理",
              orderNum: 1,
              parentId: 0,
              perms: "Special",
              updateTime: "2021-04-23 13:39:05",
              url: "/book",
            },
          ],
          createTime: "2021-04-13 13:13:22",
          icon: "el-icon-collection",
          id: 2,
          menuType: "DIRECTORY",
          name: "内容管理",
          orderNum: 1,
          parentId: 0,
          perms: "Special",
          updateTime: "2021-04-23 13:39:05",
          url: "/special",
        },
        {
          children: [
            {
              createTime: "2021-04-13 13:13:22",
              id: 4,
              menuType: "DIRECTORY",
              name: "留言",
              orderNum: 1,
              parentId: 0,
              perms: "Leave",
              updateTime: "2021-04-23 13:39:05",
              url: "/message/leave",
            },
            {
              createTime: "2021-04-13 13:13:22",
              id: 5,
              menuType: "DIRECTORY",
              name: "回答集锦",
              orderNum: 1,
              parentId: 0,
              perms: "Answer",
              updateTime: "2021-04-23 13:39:05",
              url: "/message/answer",
            },
          ],
          createTime: "2021-04-13 13:13:22",
          icon: "el-icon-chat-dot-square",
          id: 3,
          menuType: "DIRECTORY",
          name: "留言管理",
          orderNum: 1,
          parentId: 0,
          perms: "Message",
          updateTime: "2021-04-23 13:39:05",
          url: "/message",
        },
        {
          children: [
            {
              createTime: "2021-04-13 13:13:22",
              id: 14,
              menuType: "MENU",
              name: "邀请码管理",
              orderNum: 1,
              parentId: 0,
              perms: "invitationCode",
              updateTime: "2021-04-23 13:39:05",
              url: "/operation/invitation",
            },
            {
              createTime: "2021-04-13 13:13:22",
              id: 15,
              menuType: "MENU",
              name: "渠道管理",
              orderNum: 1,
              parentId: 0,
              perms: "Channel ",
              updateTime: "2021-04-23 13:39:05",
              url: "/operation/channel",
            },
          ],
          createTime: "2021-04-13 13:13:22",
          icon: "el-icon-data-line",
          id: 13,
          menuType: "DIRECTORY",
          name: "运营管理",
          orderNum: 1,
          parentId: 0,
          perms: "Operation",
          updateTime: "2021-04-23 13:39:05",
          url: "/operation",
        },
        {
          children: [],
          createTime: "2021-04-13 13:13:22",
          icon: "el-icon-user",
          id: 10,
          menuType: "DIRECTORY",
          name: "用户管理",
          orderNum: 1,
          parentId: 0,
          perms: "User",
          updateTime: "2021-04-23 13:39:05",
          url: "/user",
        },
        {
          children: [
            {
              children: [],
              createTime: "2021-04-23 13:53:09",
              id: 7,
              menuType: "MENU",
              name: "角色管理",
              orderNum: 0,
              parentId: 1,
              perms: "Role",
              url: "/systemManagement/role",
            },
            {
              children: [],
              createTime: "2021-04-23 13:53:38",
              id: 8,
              menuType: "MENU",
              name: "管理员管理",
              orderNum: 0,
              parentId: 1,
              perms: "Administrator",
              url: "/systemManagement/administrator",
            },
            {
              children: [],
              createTime: "2021-04-13 13:13:56",
              icon: "el-icon-lock",
              id: 9,
              menuType: "MENU",
              name: "菜单管理",
              orderNum: 1,
              parentId: 1,
              perms: "Menu",
              updateTime: "2021-04-23 13:40:09",
              url: "/systemManagement/menu",
            },
          ],
          createTime: "2021-04-13 13:13:22",
          icon: "el-icon-s-tools",
          id: 6,
          menuType: "DIRECTORY",
          name: "系统管理",
          orderNum: 1,
          parentId: 0,
          perms: "SystemManagement",
          updateTime: "2021-04-23 13:39:05",
          url: "/systemManagement",
        },
      ], // 菜单列表
      activePath: "", // 当前路由
    };
  },
  created() {
    this.search();
  },
  methods: {
    //获取左侧菜单栏
    search() {
      //this.menuList = JSON.parse(sessionStorage.getItem("menuTree"));
    },
    // async search() {
    //   const { data: res } = await obtainMenuList()
    //   this.menuList = res.data
    //   console.log(this.menuList)
    //   console.log(JSON.parse(sessionStorage.getItem('menuTree')))
    // },
    saveNavState() {},
    handleClick(item) {
      console.log("aa" + item);
    },
  },
};
</script>
<style scoped lang="less">
.menuList {
  background-color: #ffffff;
  .el-menu {
    border: none;
  }

  a {
    text-decoration: none;
  }

  .router-link-active {
    text-decoration: none;
  }
}
</style>
import request from "@/utils/request"

// 菜单列表
export function obtainMenuList() {
  return request({
    url: "/menuRight/list",
    method: "get"
  })
}

// 新增菜单
export function addMenuList(data) {
  return request({
    url: "/menuRight/save",
    method: "post",
    data
  })
}

// 编辑菜单
export function updataMenuList(data) {
  return request({
    url: "/menuRight/update",
    method: "put",
    data
  })
}

// 删除菜单
export function deleteMenuList(data) {
  return request({
    url: "/menuRight/deleteById",
    method: "delete",
    params: data
  })
}

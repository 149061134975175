import request from "@/utils/request"

// 登录
export function login(data) {
  return request({
    url: "/login/admin",
    method: "post",
    data
  })
}

// 修改密码
export function updatepwd(data) {
  return request({
    url: "/user/updatePwd",
    method: "put",
    data
  })
}
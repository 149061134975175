<template>
  <transition mode="out-in">
    <router-view style="height: 100%" />
  </transition>
</template>

<script>
export default {

}
</script>

<style scoped>
/* 动画 */
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.35s ease-in-out;
}
/* 动画 */
</style>
